<template>
  <div class="card">
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="mr-4 h1">
          <router-link to="/ticketing"
            ><div><b-icon-arrow-left></b-icon-arrow-left></div
          ></router-link>
        </div>
        <div class="mb-10">
          <h2>
            Ticket Counter/Site (BCA 493E Indomaret TJOJ Tukad Badung Denpasar)
          </h2>
        </div>
      </div>
      <div class="float-left ml-5 mb-5">
        <b-button variant="danger"
          ><b-icon-upload></b-icon-upload> Export
        </b-button>
        <!-- <b-button variant="warning" class="ml-5 text-dark"
          ><b-icon-download></b-icon-download> Import
        </b-button> -->
      </div>

      <div class="float-right">
        <div class="input-group">
          <div class="h2 mr-2 mt-1">
            <b-icon-search></b-icon-search>
          </div>
          <div class="">
            <b-form-input
              class=""
              id="input-1"
              type="text"
              placeholder="Search Here"
            ></b-form-input>
          </div>
          <div>
            <b-button variant="danger" class="btn-sm ml-4">
              Send Ticket to Saber</b-button
            >
          </div>
        </div>
      </div>

      <div class="anyClasspend container-fluid">
        <b-tabs
          content-class="mt-3"
          fill
          nav-wrapper-class="sticky-top bg-white col-12"
        >
          <!-- PROGRESS TICKETS -->
          <b-tab title="Progress ">
            <b-table
              hover
              :items="items"
              :fields="fields"
              class="text-center mt-5"
              responsive
            >
              <template class="text-center" #cell(actions)="">
                <div class="row d-flex justify-content-center">
                  <b-button
                    class="mr-2 ml-2"
                    @click="editvisit = !editvisit"
                    variant="warning"
                  >
                    Update
                  </b-button>

                  <button class="mr-2 text text-danger" @click="hapus(index)">
                    <b-icon-trash></b-icon-trash>
                  </button>
                </div>
              </template>
            </b-table>
          </b-tab>

          <!-- FINAL TICKETS -->
          <b-tab title="Final">
            <b-table
              hover
              :items="items"
              :fields="fields"
              class="text-center mt-5"
              responsive
            >
              <template class="text-center" #cell(actions)="">
                <div class="row d-flex justify-content-center">
                  <b-button
                    class="mr-2 ml-2"
                    @click="editvisit = !editvisit"
                    variant="warning"
                  >
                    Update
                  </b-button>

                  <button class="mr-2 text text-danger" @click="hapus(index)">
                    <b-icon-trash></b-icon-trash>
                  </button>
                </div>
              </template>
            </b-table>
          </b-tab>
          <!-- Modal Edit -->
          <b-modal
            v-model="editvisit"
            size="xl"
            ref="my-modaledit"
            title="Update Ticket for Early Progress"
            hide-footer
            centered
          >
            <b-form class="mt-4">
              <b-table
                hover
                :items="itemseditearly"
                :fields="fieldseditearly"
                responsive
              >
                <template class="text-center" #cell(actions)="">
                  <div class="d-flex justify-content-center">
                    <input
                      type="checkbox"
                      :value="checkboxVal"
                      :checked="booleanValue"
                      v-on:input="checkboxVal = $event.target.value"
                    />
                  </div>
                </template>
              </b-table>

              <div class="col-12 row">
                <div class="form-group mt-8 col-6">
                  <label>Saber:</label>
                  <input
                    type="text"
                    value="Cecep Mahendra"
                    disabled
                    placeholder="Fill out this form..."
                    class="form-control"
                  />
                </div>
                <div class="form-group mt-8 col-6">
                  <label>Email: </label>
                  <input
                    type="text"
                    value="cecep.mahendra@gmail.com"
                    disabled
                    placeholder="Fill out this form..."
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12 row">
                <div class="form-group mt-8 col-6">
                  <label>Category:</label>
                  <div class="form-group">
                    <b-form-select
                      v-model="selectedcat"
                      :options="optionscat"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-group mt-8 col-6">
                  <label>Sub Category:</label>
                  <div class="form-group">
                    <b-form-select
                      v-model="selectedsub"
                      :options="optionssub"
                    ></b-form-select>
                  </div>
                </div>
              </div>

              <div class="form-group mt-5">
                <label for="">Description: </label>
                <b-form-textarea
                  id="textarea"
                  v-model="text"
                  placeholder="Fill out this form..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>

              <div class="d-flex justify-content-center">
                <b-button class="mr-5" @click="hideModal()">Update</b-button>
                <b-button class="btn btn-danger">Closed</b-button>
              </div>
            </b-form>
            <!-- <pre>{{ infoModal.content }}</pre> -->
          </b-modal>
          <!-- FAKE TABS -->
          <b-tab title="" disabled> </b-tab>
          <b-tab title="" disabled> </b-tab>
          <b-tab title="" disabled> </b-tab>
          <b-tab title="" disabled> </b-tab>
          <b-tab title="" disabled> </b-tab>
        </b-tabs>
      </div>
    </div>
    <div>
      <b-button variant="success" class="btn-sm mt-4 mr-4 mb-4 ml-4 float-right"
        >Approve All Ticket</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedcat: null,
      optionscat: [
        { value: null, text: "Kategori A" },
        { value: "a", text: "Kategori B" },
        { value: "b", text: "Kategori C" },
        { value: "c", text: "Kategori D" },
        { value: "d", text: "Kategori E" },
      ],
      selectedsub: null,
      optionssub: [
        { value: null, text: "Sub Kategori A" },
        { value: "a", text: "Sub Kategori B" },
        { value: "b", text: "Sub Kategori C" },
        { value: "c", text: "Sub Kategori D" },
        { value: "d", text: "Sub Kategori E" },
      ],
      editvisit: false,
      modalShow: false,
      items: [
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
        {
          namaticket: "fef4t5451325fe12443t56grfgvfadf45fdf3132",
          cat: "Incedent",
          prob: "Suhu ruangan panas ",
          timeattemp: "11/11/2021, 15:53:08 - 11/11/2021, 15:53:08",
          stat: "Sending Ticket",
          pic: "Cecep Mahendra",
        },
      ],
      itemseditearly: [
        {
          desc: "Pending replace batery sensor ruang acc om abdul",
          cat: "Panel Offline",
          prob: "Suhu Ruangan Panas",
          timeupdate: "11/11/2021, 15:00:20 - 11/11/2021, 15:00:20",
          pic: "Henry Himawan",
        },
      ],
      fieldseditearly: [
        {
          key: "desc",
          label: "Description",
          class: "text-center",
        },
        {
          key: "cat",
          label: "Category",
          sortable: false,
          class: "text-center",
        },
        {
          key: "prob",
          label: "Problem",
          sortable: false,
          class: "text-center",
        },
        {
          key: "timeupdate",
          label: "Time of Update",
          sortable: false,
          class: "text-center",
        },
        {
          key: "pic",
          label: "PIC / Operator",
          sortable: false,
          class: "text-center",
        },
        {
          formatter: (value) => {
            return value ? "Yes" : "No";
          },

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      fields: [
        {
          label: "No",
        },
        {
          key: "namaticket",
          label: "Ticket ID",
          sortable: true,
          class: "text-center",
        },
        {
          key: "cat",
          label: "Category",
          sortable: true,
        },

        {
          key: "prob",
          label: "Problem",
          sortable: true,
          class: "text-center",
        },
        {
          key: "timeattemp",
          label: "Update Time",
          sortable: true,
          class: "text-center",
        },
        {
          key: "pic",
          label: "Operator",
          sortable: true,
          class: "text-center",
        },
        {
          key: "stat",
          label: "Status",
          sortable: true,
          class: "text-center",
        },

        {
          formatter: (value) => {
            return value ? "Yes" : "No";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "actions", label: "Actions" },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    hapus(index) {
      this.items.splice(index, 1);
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
  },
};
</script>

<style></style>
